<template>
  <b-modal
    :title="$t('GERAL.ESQUECEU_SENHA')"
    header-bg-variant="light"
    ref="modal-senha"
    size="md"
    hide-footer
    @hidden="fecharModal()"
  >
    <b-card-text>
      <input-text
        ref="email"
        v-model="form.email"
        :label="$t('GERAL.EMAIL')"
        :placeholder="$t('GERAL.EMAIL')"
        required
      />
      <input-text
        class="user-input"
        ref="cnpj"
        v-model="form.cpf"
        :label="$t('USUARIOS.TABELA.CPF')"
        :placeholder="$t('USUARIOS.TABELA.CPF')"
        v-mask="['###.###.###-##']"
        required
      />

      <b-button variant="primary" size="lg" block class="mt-4" @click="enviarEmail">{{
        $t("GERAL.TROCAR_SENHA")
      }}</b-button>
    </b-card-text>
  </b-modal>
</template>

<script>
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import InputText from "@/components/inputs/InputText";
import UsuarioService from "@/common/services/usuario/usuario.service";

export default {
  name: "ModalEsqueceuSenha",
  components: {
    InputText,
  },
  data() {
    return {
      form: {
        usuarioId: this.$route.query.usuarioId,
        email: null,
        cpf: null,
      },
    };
  },
  props: {
    mostrarModal: { type: Boolean, default: false },
  },
  watch: {
    mostrarModal(valor) {
      if (valor) this.$refs["modal-senha"].show();
      else this.$refs["modal-senha"].hide();
    },
  },
  methods: {
    fecharModal() {
      this.$emit("fecharModal");
    },
    validarFormulario() {
      let arValidation = [];
      arValidation.push(this.$refs.email.valid());
      //arValidation.push(this.$refs.cpf.valid());

      return arValidation.filter((elem) => elem == false).length == 0;
    },
    enviarEmail() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      const form = this.form;
      UsuarioService.esqueceuSenha(form)
        .then(() => {
          this.alertaSucesso("Alteração de senha solicitada com sucesso!");
          this.fecharModal();
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
