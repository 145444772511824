<template>
  <div id="login" style="min-height: calc(100vh - 20px)" tag="section">
    <b-row class="justify-content-center no-gutters">
      <b-col lg="12" class="d-flex align-items-center">
        <b-card no-body class="w-100">
          <b-row class="no-gutters" style="min-height: 100vh">
            <b-col
              lg="6"
              class="bg-primary d-md-flex align-items-center justify-content-center valorem-img-bgd"
              style="border-radius: 0% 30px 30px 0%"
            >
              <div class="d-flex align-items-center">
                <div class="p-5">
                  <b-row class="justify-content-center">
                    <b-col cols="12" lg="9" xl="9">
                      <div>
                        <h2 class="display-7 text-white fw-medium">
                          {{ $t("GERAL.PAGINA_PRINCIPAL_TITULO") }}
                        </h2>
                        <p class="mt-4 text-white op-5 font-weight-normal">
                          {{ $t("GERAL.PAGINA_PRINCIPAL_MSG") }}
                        </p>
                        <div class="ml-auto">
                          <b-button
                            @click="saibaMais"
                            size="lg"
                            pill
                            variant="outline-light"
                            >{{ $t("GERAL.SAIBA_MAIS") }}</b-button
                          >
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
            <b-col lg="6" class="d-md-flex align-items-center justify-content-center">
              <div class="d-flex align-items-center w-100">
                <div class="p-5 w-100">
                  <b-row class="justify-content-center">
                    <b-col cols="12" lg="11" xl="10">
                      <div class="text-center">
                        <img src="@/assets/images/logo.svg" class="valorem-logo mb-4" />
                      </div>
                      <div>
                        <login-erro v-if="loginErro" />
                        <trocar-senha v-if="primeiroLogin" />
                        <login-form
                          v-if="primeiroLogin == false"
                          @loginErro="
                            (e) => {
                              loginErro = e;
                            }
                          "
                          @precisaAlterarSenha="(e) => precisaAlterarSenha(e)"
                        />
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <modal-esqueceu-senha :mostrarModal="mostrarModal" @fecharModal="fecharModal()" />
  </div>
</template>

<script>
import LoginForm from "./LoginForm.vue";
import TrocarSenha from "./TrocarSenha.vue";
import LoginErro from "./LoginErro.vue";
import ModalEsqueceuSenha from "./components/ModalEsqueceuSenha";
export default {
  name: "Login",
  components: {
    ModalEsqueceuSenha,
    LoginForm,
    LoginErro,
    TrocarSenha,
  },
  data() {
    return {
      cadastroSucesso: false,
      loginErro: false,
      primeiroLogin: false,
      tabIndex: window.location.pathname == "/cadastro-sacado" ? 1 : 0,
      mostrarModal: false,
    };
  },
  created() {
    window.addEventListener("beforeunload", this.removeUser);
  },
  methods: {
    removeUser() {
      if (this.$router.currentRoute.path != "/login") return;
      localStorage.removeItem("usuario_token");
    },
    validarFormulario() {
      let arValidation = [];
      arValidation.push(this.$refs.senha.valid());

      return arValidation.filter((elem) => elem == false).length == 0;
    },
    precisaAlterarSenha(e) {
      this.primeiroLogin = e;
    },

    abrirModal() {
      this.mostrarModal = true;
    },
    fecharModal() {
      this.mostrarModal = false;
    },
    saibaMais() {
      window.location.href = "https://www.valorem.com.br/antecipacao-de-recebiveis/";
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["tabs-autenticacao", "active-tab"];
      } else {
        return ["tabs-autenticacao"];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-link {
  padding: 1rem 1.5rem;
  font-weight: 600;
}
.tab-autenticacao {
  min-height: 500px;
}
.active-tab {
  background-color: var(--primary) !important;
  color: white !important;
}
</style>
